@charset "utf-8";

//General
@import "fonts";
@import "variables";
@import "icons";

.overflow-code {
  overflow-x: auto;
}

.has-text-green {
  color: green;
}

// Import only what you need from Bulma
@import "bulma";

// Import only what you need from Fontawesome
@import "fontawesome";

// Syntax highlight
@import "/node_modules/prismjs/themes/prism.css";
@import "syntax";

//Specials:
.text-footer {
  line-height: 2;
  font-size: .8rem;

  @include tablet {
    padding-left: 3rem !important;
  }
}

// Overwrite icon color in nav bar
.navbar-item {
  a {
    color: $grey-darker;
    &:hover {
      color: $red;
    }
  }
}

#navbarTop {
  .navbar-item {
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 0em;
    padding-bottom: 0em;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid $orange;

      @include mobile {
        border-bottom: none;
      }
    }
  }
}

//Margin mobile
@include mobile {
.mtm-1 {
  margin-top: 1rem;
}
.mtm-3 {
  margin-top: 3rem;
}
}
